// React
import React from 'react';

// Gatsby
import { useStaticQuery, graphql } from 'gatsby';

// Emotion / styling
import styled from '@emotion/styled';
import s from 'src/styles';

// Packages

// Utilities
import { localize, localizeLink } from 'utilities';
import { useTheme, useResponsive } from 'hooks';
import { TransitionLink } from 'transition';

// Components
import SEO from 'components/seo';
import { PageNavigation } from 'components/pageNavigation';
import Image from 'components/image';

const GuidesPageComponent = ({ pageContext, content, location }) => {
    const { locale } = pageContext;
    const { seoMetaTags, title, guides, slug } = content;

    // Set theme
    const theme = useTheme(s.color('white'));

    // Breakpoints
    const breakpoint = useResponsive();

    return (
        <>
            <s.layout.PageContainer>
                <SEO {...{ seoMetaTags, locale }} />
                <ContentWrapper>
                    <PageNavigation {...{ theme }}>
                        <span>{title}</span>
                    </PageNavigation>
                    <Guides>
                        {guides.map(guide => (
                            <Guide key={guide.id}>
                                <TransitionLink
                                    {...{
                                        from: location,
                                        to: localizeLink(
                                            `/${slug}/${guide.slug}/`,
                                            locale
                                        ),
                                    }}>
                                    <ImageWrapper>
                                        <Image
                                            {...{
                                                source: guide.thumbnail.fluid,
                                                alt: guide.title,
                                            }}
                                        />
                                        {breakpoint > 20 && <Hover />}
                                    </ImageWrapper>
                                    <Caption>{guide.name}</Caption>
                                </TransitionLink>
                            </Guide>
                        ))}
                    </Guides>
                </ContentWrapper>
            </s.layout.PageContainer>
        </>
    );
};

const ContentWrapper = styled.div`
    ${s.responsive.property('padding-top', {
        0: 100,
        30: 140,
    })};
`;

const Hover = styled.div`
    width: 100%;
    height: 1px;
    background: ${s.color('black').default()};
    position: absolute;
    top: calc(100% + 15px);
    transition: transform 400ms ${s.easings.smooth.out};
    transform-origin: 0 0;
    transform: scaleX(0);
`;

const Guides = styled.div`
    display: flex;
    flex-wrap: wrap;

    margin-top: 2em;
`;

const Guide = styled.div`
    position: relative;
    ${s.grid.gutter('margin-bottom', {
        0: '3',
    })};
    &:nth-of-type(2n) {
        ${s.grid.gutter('margin-left', {
            0: '0',
            20: 2,
        })};
    }

    ${s.responsive.mq(30)} {
        &:hover {
            ${Hover} {
                transform: scaleX(1);
            }
        }
    }
`;

const ImageWrapper = styled.div`
    position: relative;
    ${s.grid.columns('width', {
        0: 4,
        20: 3,
        30: 6,
    })};
`;

const Caption = styled.div`
    margin-top: 2em;
    ${s.fonts.style('h11')};
`;

const NamedGuidesPageComponent = props => {
    const { locale } = props.pageContext;
    const { allDatoCmsPageGuide, allDatoCmsBrewGuide } = useStaticQuery(graphql`
        query {
            allDatoCmsPageGuide {
                edges {
                    node {
                        locale
                        slug
                        title
                        seoMetaTags {
                            ...SEO
                        }
                    }
                }
            }
            allDatoCmsBrewGuide {
                edges {
                    node {
                        id
                        locale
                        name
                        slug
                        thumbnail {
                            fluid(maxWidth: 800) {
                                ...GatsbyDatoCmsFluid_noBase64
                            }
                        }
                    }
                }
            }
        }
    `);

    return (
        <GuidesPageComponent
            {...{
                content: {
                    ...localize(allDatoCmsPageGuide, locale),
                    guides: localize(allDatoCmsBrewGuide, locale),
                },
                ...props,
            }}
        />
    );
};

export default NamedGuidesPageComponent;
